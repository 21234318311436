import React from "react"
import AccountLayout from "../../../components/accountLayout"
import AccountManagerReview from "../../../components/accountManagerReview"

const AccountManagerReviewPage = () => {
  return (
    <AccountLayout title="Add an account manager" to="/account-details/status">
      <form id="form-id">
        <AccountManagerReview />
      </form>
    </AccountLayout>
  )
}

export default AccountManagerReviewPage
