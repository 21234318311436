import { Dispatch } from "@reduxjs/toolkit"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import "../assets/styles/components/accountManagerCard.scss"
import { deleteManager } from "../helper/firebase/transaction/profile.trx"

const AccountManagerCard = (props: { [keys: string]: any }) => {
  const { name } = props

  const dispatch = useDispatch()

  const customDeleteManager = deleteManager(dispatch as Dispatch)
  const userStatus = useSelector((state: any) => state.auth.userStatus)
  const kycProfileStatus = useSelector((state: any) => state.profileKyc.kycProfileStatus)
  const accountManagers = kycProfileStatus?.profile?.accountManagers
  const otherAccountManagers = accountManagers?.otherAccountManagers

  const handleRemove = (name: string) => {
    const mannagers = otherAccountManagers.filter(
      (manager: any) => `${manager.managerFirstName} ${manager.managerLastName}` != name
    )

    customDeleteManager(mannagers, userStatus, kycProfileStatus)
  }

  return (
    <div id={name} className="account-manager-card">
      <div className="manager">
        <div className="manager-pic">
          <svg
            viewBox="0 0 32 32"
            className="pic-icon"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            role="presentation"
            focusable="false"
          >
            <path d="m16 1c8.2842712 0 15 6.71572875 15 15 0 8.2842712-6.7157288 15-15 15-8.28427125 0-15-6.7157288-15-15 0-8.28427125 6.71572875-15 15-15zm0 8c-2.7614237 0-5 2.2385763-5 5 0 2.0143973 1.2022141 3.7998876 2.9996346 4.5835001l.0003231 2.0984999-.1499943.0278452c-2.8326474.5613112-5.31897338 2.2230336-6.93575953 4.5872979 2.34343054 2.291067 5.54974273 3.7028569 9.08579613 3.7028569 3.5355506 0 6.7414538-1.4113884 9.0850203-3.701476-1.6141801-2.3628535-4.0978119-4.0247647-6.929184-4.5867938l-.1558786-.0287302.001228-2.0991413c1.7288399-.7547474 2.9066959-2.4357565 2.9936498-4.355479l.0051645-.2283797c0-2.7614237-2.2385763-5-5-5zm0-6c-7.17970175 0-13 5.82029825-13 13 0 2.9045768.95257276 5.5866683 2.56235849 7.7509147 1.42074739-1.9134907 3.33951478-3.4002416 5.53860831-4.2955956l.3480332-.1363191-.0229565-.0189706c-1.43704227-1.2411241-2.34462949-3.045583-2.42083359-5.0285539l-.00520991-.2714755c0-3.8659932 3.1340068-7 7-7s7 3.1340068 7 7c0 1.9941317-.8415062 3.8279876-2.224566 5.1193683l-.225434.2006317.0447787.0163138c2.3268368.8792152 4.3570558 2.4138611 5.8430586 4.4127726 1.6098837-2.1632453 2.5621627-4.8449575 2.5621627-7.7490864 0-7.17970175-5.8202983-13-13-13z"></path>
          </svg>
        </div>
        <div className="manager-details">
          <p className="manager-name">{name}</p>
          <div className="manager-info">
            <p className="manager-title">Account Manager</p>

            <div className="action-buttons">
              <button type="button" className="btn">
                Edit
              </button>
              <button onClick={() => handleRemove(name)} type="button" className="btn">
                Remove
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountManagerCard
