import React from "react"
import "../assets/styles/components/accountManagerReview.scss"
import { Link } from "gatsby"
import AccountManagerCard from "./accountManagerCard"
import { useSelector } from "react-redux"

const AccountManagerReview = () => {
  const kycProfileStatus = useSelector((state: any) => state.profileKyc.kycProfileStatus)
  const accountManagers = kycProfileStatus?.profile?.accountManagers
  const otherAccountManagers = accountManagers?.otherAccountManagers

  return (
    <div className="account-manager-review">
      <h3 className="h3-heading">Any other account managers?</h3>
      <p className="desc-1">
        Only add the main people who log in to manage reservations or payments.
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </p>
      {otherAccountManagers?.map((manager: any, key: any) => (
        <AccountManagerCard key={key} name={`${manager.managerFirstName} ${manager.managerLastName}`} />
      ))}

      <Link to="/account-details/single-professional-host/account-manager">
        <div className="add-btn">
          <button type="button" className="btn">
            <span className="add">
              <span className="add-icon">+</span>
              <span className="add-text">Add</span>
            </span>
          </button>
        </div>
      </Link>
    </div>
  )
}

export default AccountManagerReview
